export const CURRENT_INDEX = "Current index";

export const productOptions = [
  "Gasoline (e.g. bob, sok, vpower, gasoline, euro)",
  "Ethanol (e.g. Methanol, ETBE)",
  "Jet (e.g. Kerosine)",
  "Middle distillates (e.g diesel b7, diesel b0, gasoil, gtl, lgo)",
  "Low carbon fuels (e.g. fame, hvo, rme)",
  "Light products (e.g. alkylate, naptha, mogas, reformate, platformate)",
  // "Ucome",
  "HSFO (e.g. DPP)",
  "LSFO (e.g. DPP)",
];

export const validProducts = [
  "Gasoline",
  "Ethanol",
  "Jet",
  "Middle distillates",
  "Low carbon fuels",
  "Light products",
  "DPP",
  "Ucome",
  "HSFO",
  "LSFO",
];

/**
 * Function to format a number to a string with two decimal places.
 * If the number does not have a toLocaleString method, it returns the number as is.
 * @param {number} number - The number to format.
 * @returns {string|number} - The formatted number.
 */
export const formatNumber = (number, decimals = 2) => {
  if (number === undefined || number === null) {
    return "";
  } else if (number.toLocaleString) {
    return number.toLocaleString("nl-NL", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  } else if (number.toFixed) {
    return number.toFixed(decimals);
  }
  return number;
};

/**
 * Function to convert seconds to an ISO string.
 * It creates a new Date object using the seconds multiplied by 1000 (to convert it to milliseconds),
 * and then returns the ISO string representation of that date.
 * @param {number} seconds - The number of seconds to convert.
 * @returns {string} - The ISO string representation of the date.
 */
export const secondsToIsoString = (seconds) => {
  const date = new Date(seconds * 1000);
  return date.toISOString();
};

/**
 * Formats a date into a string in the format "dd/mm/yyyy".
 * @param {{string}} date - The date to be formatted.
 * @returns {string} The formatted date string.
 */
export const formatDate = (date) => {
  return new Date(date._seconds * 1000)
    .toLocaleDateString("nl-NL")
    .replace(/-/g, "/");
};

/**
 * Function to format a number to a string with no decimal places.
 * If the number does not have a toLocaleString method, it returns the number as is.
 * @param {number} number - The number to format.
 * @returns {string|number} - The formatted number.
 */
export const formatTons = (number) => {
  if (number.toLocaleString) {
    return number.toLocaleString("nl-NL");
  }
  return number;
};

/**
 * Function to format areas for display.
 * It filters areas with more than one city and creates a map of area names and cities.
 * It then filters areas with destinations and creates a map of area names and destinations.
 * @param {Array} data - The data to format.
 * @returns {Object} - The formatted data.
 */
export const areasForDisplay = (data) => {
  const areaMap = data
    .filter((area) => area.cities.length > 1)
    .reduce(
      (acc, area) => ({
        ...acc,
        [area.area + area.name]: area.cities.join(", "),
      }),
      {},
    );

  return data
    .filter((area) => area.destinations.length > 0)
    .reduce((result, area) => {
      const areaKey = `${area.name}${area.cities.length > 1 ? ` (${area.cities.join(", ")})` : ""
        }`;
      const destinations = area.destinations.map(
        (destination) =>
          `${destination}${areaMap[area.area + destination]
            ? ` (${areaMap[area.area + destination]})`
            : ""
          }`,
      );

      return {
        ...result,
        [area.area]: { ...result[area.area], [areaKey]: destinations },
      };
    }, {});
};

/**
 * Function to format port names.
 * Specifically, it removes the content within brackets when the area on the freight index is set to CPP Rhine.
 * @param {string} portName - The name of the port.
 * @param {string} area - The area on the freight index.
 * @returns {string} - The formatted port name.
 */
export const formatPortName = (portName, area) => {
  if (area === "CPP Rhine") {
    return portName.replace(/\s?\(.*?\)/, "");
  }
  return portName;
};
