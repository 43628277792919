import * as React from "react";
import { Grid } from "@mui/material";
import "firebase/compat/auth";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../GeneralErrorPage";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker";
import "./style.css";
import Terminals from "./Rivers";
import Markers from "./Markers";
import Mountain from "./Mountain";
import StationPoints from "./StationPoints";
import Ports from "./Ports";
import Legend from "./Legend";

function WaterLevelMap({ graphs }) {
  const { t } = useTranslation();

  return (
    <Grid
      container
      sx={{
        width: "85vw",
        heigth: "86vh",
        position: "relative",
        justifyContent: "center",
      }}
    >
      <MapContainer
        center={[49.41986821800572, 7.941116146878102]}
        zoom={6.5}
        style={{ width: "100%", height: "86vh" }}
        position="bottomright"
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position="topright" />
        <LayersControl position="topleft">
          <Terminals />
          <Markers />
          <Mountain />
          <StationPoints graphs={graphs} />
          <Ports />
        </LayersControl>
      </MapContainer>
      <Legend />
    </Grid>
  );
}

export default withErrorBoundary(WaterLevelMap, {
  fallback: <GeneralErrorPage />,
});
